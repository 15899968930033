import { put } from "helpers/ApiHelper";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Alert } from "reactstrap";

const UsercoderModal = ({ showModal, toggleModal, ticketId }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const sendForm = async () => {
    const response = await put(`tickets/${ticketId}/status/2`);

    if (response.status !== 200) {
      setErrorMessage(response.data.message);
    } else {
      toggleModal();
    }
  };

  return (
    <Modal centered toggle={toggleModal} isOpen={showModal}>
      <ModalHeader className="justify-content-center">
        ¿Esta seguro?
      </ModalHeader>
      <ModalBody className="">
        {errorMessage && (
          <div>
            <Alert color="danger">{errorMessage}</Alert>
          </div>
        )}
        <section className="hstack justify-content-around">
          <div className="d-grid">
            <Button size="lg" onClick={toggleModal}>
              Cancelar
            </Button>
          </div>
          <div className="d-grid">
            <Button color="info" size="lg" onClick={sendForm}>
              Aceptar
            </Button>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default UsercoderModal;
