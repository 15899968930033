import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LightboxCustom = ({ images, selectedImg, isOpen, toggleOpen }) => {
  const [imgIdx, setImageIndex] = useState(0);

  useEffect(() => {
    if (!selectedImg) return;

    setImageIndex(selectedImg);
  }, [selectedImg]);

  const changeImage = (step) => {
    setImageIndex((prev) => (prev + step) % images.length);
  };

  if (!isOpen || !images) return <></>;

  return (
    <Lightbox
      mainSrc={images[imgIdx]}
      nextSrc={images[imgIdx + 1]}
      prevSrc={images[imgIdx - 1]}
      onCloseRequest={toggleOpen}
      onMovePrevRequest={() => changeImage(-1)}
      onMoveNextRequest={() => changeImage(1)}
    />
  );
};

export default LightboxCustom;
