import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextInput, SelectInput } from "../../Form/";
import { get } from "helpers/ApiHelper";
import { useForm } from "components/Form";
import { useParams, Link } from "react-router-dom";
import { CheckInput } from "components/Form";
import { useFetchSelect } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const { requestMessage, errors, sendForm } = useForm();
  const [data, setData] = useState({ is_usercoder: false });
  const [roles, setRoles] = useState({ usercoders: [], clients: [] });

  const companies = useFetchSelect("companies");

  useEffect(() => {
    const getRoles = async () => {
      const mapRoles = (item) => ({
        label: item.name,
        value: item.id,
        isDisabled: item.id === 1,
      });

      const response = await get("roles");

      const usercoders = response.data
        .filter((item) => item.id !== 4)
        .map(mapRoles);
      const clients = response.data
        .filter((item) => item.id === 4)
        .map(mapRoles);

      if (response.status === 200)
        setRoles({
          usercoders,
          clients,
        });
    };

    getRoles();
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("users/" + id);
    setData({
      ...response.data,
      roles: response.data.roles.map((item) => item.id),
      is_usercoder: response.data.usercoder !== null,
      slack_id: response.data.usercoder?.slack_id,
      company_id: response.data.client?.company_id,
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => {
      if (name === "is_usercoder") {
        delete prevData.roles;
        delete prevData.company_id;
        delete prevData.slack_id;
      }

      return { ...prevData, [name]: value };
    });
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Usuario / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("users", data, id)}>
          <fieldset>
            <h3>Detalle Usuario</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            <TextInput
              name="lastname"
              label="Apellido"
              placeholder="-- Ingrese Apellido --"
              value={data.lastname}
              onChange={handleInput}
              error={errors.lastname}
              disabled={see}
            />
            <TextInput
              name="email"
              label="Email"
              placeholder="-- Ingrese email --"
              value={data.email}
              onChange={handleInput}
              error={errors.email}
              disabled={see}
            />
            <CheckInput
              name="is_usercoder"
              label="Tipo de usuario"
              value={data.is_usercoder}
              error={errors.is_usercoder}
              onChange={handleInput}
              inline={true}
              type="radio"
              content={[
                { label: "Cliente", value: false },
                { label: "Usercoder", value: true },
              ]}
            />
            <SelectInput
              name="roles"
              label="Rol"
              placeholder="-- Ingrese rol --"
              options={data.is_usercoder ? roles.usercoders : roles.clients}
              value={data.roles}
              onChange={handleInput}
              isMultiple
              error={errors.roles}
              disabled={see}
            />
            {data.is_usercoder ? (
              <TextInput
                name="slack_id"
                label="ID de slack"
                placeholder="-- Ingrese ID de slack --"
                value={data.slack_id}
                onChange={handleInput}
                error={errors.slack_id}
                disabled={see}
              />
            ) : (
              <SelectInput
                name="company_id"
                label="Compañía"
                placeholder="-- Ingrese compañia --"
                options={companies}
                value={data.company_id}
                onChange={handleInput}
                error={errors.company_id}
                disabled={see}
              />
            )}
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/users" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
