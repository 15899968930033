import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../../store/actions";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import logo from "../../assets/Piloto_Go/Images/pilotoGO_logo.png";
import logo_small from "../../assets/Piloto_Go/Images/50x50 logo.png";
import SidebarAdmin from "./SidebarAdmin";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
  }

  async componentDidMount() {
    this.setState({ user: await JSON.parse(localStorage.getItem("authUser")) });
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo_small} alt="" height="22" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo_small} alt="" height="30" />
              </span>
              <span className="logo-lg">
                <img src={logo} alt="" height="70" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            {this.state.user ? (
              <SidebarAdmin />
            ) : (
              <></>
              /* <SidebarContent /> */
            )}
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
