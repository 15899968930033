import { useFetchSelect } from "components/Form";
import { SelectInput } from "components/Form";
import { get, put } from "helpers/ApiHelper";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const UsercoderModal = ({ showModal, toggleModal, ticket }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [usercoders, setUsercoders] = useState([]);

  const [selectedUsercoder, setSelectedUsercoder] = useState();

  useEffect(() => {
    setErrorMessage("");

    if (!ticket) return;

    setSelectedUsercoder(ticket.usercoder_id);
  }, [ticket]);

  useEffect(() => {
    get("usercoders").then((res) => {
      if (res.status !== 200) {
        setUsercoders([]);
        return;
      }

      setUsercoders(
        res.data.map((item) => ({
          label: `${item.user.name} ${item.user.lastname}`,
          value: item.id,
        }))
      );
    });
  }, []);

  const onChange = (_name, value) => {
    setSelectedUsercoder(value);
  };

  const sendForm = async () => {
    const response = await put(`tickets/${ticket.id}`, {
      ...ticket,
      usercoder_id: selectedUsercoder,
    });

    if (response.status !== 200) {
      setErrorMessage(response.data.message);
    } else {
      toggleModal();
    }
  };

  if (!ticket) return <></>;

  return (
    <Modal centered toggle={toggleModal} isOpen={showModal}>
      <ModalHeader className="justify-content-center">
        Asignar Agente {ticket.id}
      </ModalHeader>
      <ModalBody className="">
        <AvForm>
          <SelectInput
            name="usercoder_id"
            label="Agente"
            options={usercoders}
            value={selectedUsercoder}
            onChange={onChange}
          />
          {errorMessage && (
            <div>
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          )}
        </AvForm>
        <section className="mt-3 hstack justify-content-around">
          <div className="d-grid">
            <Button size="lg" onClick={toggleModal}>
              Cancelar
            </Button>
          </div>
          <div className="d-grid">
            <Button color="info" size="lg" onClick={sendForm}>
              Aceptar
            </Button>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default UsercoderModal;
