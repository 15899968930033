import { checkRole } from "helpers/getUser";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { get } from "helpers/ApiHelper";
import { Link } from "react-router-dom";

const Landing = () => {
  const [tickets, setTickets] = useState([]);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    get("tickets").then((res) => setTickets(res.data));

    if (checkRole(["Jefe de Proyecto", "Desarrollador", "QA"])) {
      get("logs").then((res) => setLogs(res.data));
    }
  }, []);

  return (
    <div>
      <Row xs={3}>
        <Col>
          <Card className="shadow h-100">
            <CardHeader>
              <CardTitle>Tickets</CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <strong>Abiertos: </strong>
                {tickets.filter((item) => item.ticketstatus_id === 1).length}
              </div>
              <div>
                <strong>Cerrados: </strong>
                {tickets.filter((item) => item.ticketstatus_id === 2).length}
              </div>
            </CardBody>
            <CardFooter className="bg-transparent">
              <Link to="tickets">Ver todos</Link>
            </CardFooter>
          </Card>
        </Col>

        {checkRole(["Jefe de Proyecto", "Desarrollador", "QA"]) && (
          <Col>
            <Card className="h-100 shadow">
              <CardHeader>
                <CardTitle>Logs</CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  <strong>Total: </strong>
                  {logs.length}
                </div>
              </CardBody>
              <CardFooter className="bg-transparent">
                <Link to="logs">Ver todos</Link>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Landing;
