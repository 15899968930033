import Landing from "../pages/Landing/Landing";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangedPassword from "pages/Authentication/ChangedPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import Forbidden from "../pages/Forbidden/index";

// Users
import UserList from "pages/Users/UserList";
import UserForm from "pages/Users/UserForm";

// Logs
import LogList from "pages/Log/LogList";
import LogForm from "pages/Log/LogForm";

// Projects
import ProjectList from "pages/Projects/ProjectList";
import ProjectForm from "pages/Projects/ProjectForm";

// Tickets
import TicketList from "pages/Tickets/TicketList";
import TicketForm from "pages/Tickets/TicketForm";

// Common routes for everyone
const authProtectedRoutes = [
  { path: "/changed-password", exact: true, component: ChangedPassword },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Landing },
];

const authProtectedRoutesJefeProyecto = [
  { path: "/changed-password", exact: true, component: ChangedPassword },
  { path: "/users", exact: true, component: UserList },
  { path: "/users/create", exact: true, component: UserForm },
  { path: "/users/edit/:id", exact: true, component: UserForm },
  { path: "/users/see/:id", exact: true, component: UserForm },

  { path: "/logs", exact: true, component: LogList },
  { path: "/logs/see/:id", exact: true, component: LogForm },

  { path: "/projects", exact: true, component: ProjectList },
  { path: "/projects/create", exact: true, component: ProjectForm },
  { path: "/projects/edit/:id", exact: true, component: ProjectForm },
  { path: "/projects/see/:id", exact: true, component: ProjectForm },

  { path: "/tickets", exact: true, component: TicketList },
  { path: "/tickets/create", exact: true, component: TicketForm },
  { path: "/tickets/edit/:id", exact: true, component: TicketForm },
  { path: "/tickets/see/:id", exact: true, component: TicketForm },
];

const authProtectedRoutesDesarrollador = [
  { path: "/changed-password", exact: true, component: ChangedPassword },

  { path: "/logs", exact: true, component: LogList },
  { path: "/logs/see/:id", exact: true, component: LogForm },

  { path: "/projects", exact: true, component: ProjectList },
  { path: "/projects/see/:id", exact: true, component: ProjectForm },

  { path: "/tickets", exact: true, component: TicketList },
  { path: "/tickets/see/:id", exact: true, component: TicketForm },
];

const authProtectedRoutesQA = [
  { path: "/changed-password", exact: true, component: ChangedPassword },

  { path: "/logs", exact: true, component: LogList },
  { path: "/logs/see/:id", exact: true, component: LogForm },

  { path: "/projects", exact: true, component: ProjectList },
  { path: "/projects/see/:id", exact: true, component: ProjectForm },

  { path: "/tickets", exact: true, component: TicketList },
  { path: "/tickets/see/:id", exact: true, component: TicketForm },
];

const authProtectedRoutesCliente = [
  { path: "/changed-password", exact: true, component: ChangedPassword },

  { path: "/logs", exact: true, component: LogList },
  { path: "/logs/see/:id", exact: true, component: LogForm },

  { path: "/projects", exact: true, component: ProjectList },
  { path: "/projects/see/:id", exact: true, component: ProjectForm },

  { path: "/tickets", exact: true, component: TicketList },
  { path: "/tickets/create", exact: true, component: TicketForm },
  { path: "/tickets/see/:id", exact: true, component: TicketForm },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:code", component: ResetPassword },
  { path: "/forbidden", component: Forbidden },
];

export {
  authProtectedRoutes,
  authProtectedRoutesJefeProyecto,
  authProtectedRoutesDesarrollador,
  authProtectedRoutesQA,
  authProtectedRoutesCliente,
  publicRoutes,
};
