/** @format */

import axios from "axios";

//apply base url for axios
let api_url;
switch (process.env.REACT_APP_API_ENV) {
  case "localhost":
    api_url = `http://localhost:8080/v1/`;
    break;
  case "development":
    api_url = `https://logger-api-dev.usercode.cl:1010/v1/`;
    break;
  case "production":
    api_url = `https://tickets-api-prod.usercode.cl:4443/v1/`;
    break;

  default:
    api_url = `https://tickets-api-prod.usercode.cl:4443/v1/`;
    break;
}

export const API_URL = api_url;

export const TOKEN = localStorage.getItem("token");

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
  validateStatus: function (status) {
    return [200, 400].includes(status); // default
  },
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  const controller = new AbortController();
  return await axiosApi
    .get(
      url,
      { ...config, signal: controller.signal },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("GET DATA: ", response.data);
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });

  // eslint-disable-next-line
  controller.abort();
}

export async function post(url, data, config = {}) {
  //   document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => {
      //   document.getElementById("main-content").classList.remove("loading");
      console.log("POST DATA: ", response.data);
      return response.data;
    })
    .catch((error) => {
      //   document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });
}

export async function put(url, data, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("PUT DATA: ", response.data);
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });
}

export async function del(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("DEL DATA: ", response.data);
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });
}
