import { AvField } from "availity-reactstrap-validation";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { post } from "helpers/ApiHelper";
import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Label,
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const { code } = props.match.params;

    this.state = {
      form_data: { code },
      response_data: [],
      form_errors: [],
    };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  //Changed value inputs
  handleChangeTextInput = async (event) => {
    // create errors outputs
    await this.setState((state) => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
      form_errors: {
        ...state.form_errors,
        ["message_error_" + event.target.name]: "",
      },
    }));
    console.log(this.state.form_data);
  };

  //send data
  async handleValidSubmit(event, _values) {
    event.preventDefault();
    // create errors outputs
    this.setState({ form_errors: {}, message_error: "", message_success: "" });
    const data = {
      ...this.state.form_data,
    };
    let response = await post(`users/password/reset`, data);

    if (response.status === 200) {
      this.setState({ message_success: response.message });
    } else {
      this.setState({
        form_errors: response.data,
        message_error: response.message,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              {" "}
              {process.env.REACT_APP_PAGE_TITLE} | Cambiar contraseña
            </title>
          </MetaTags>
          <Container fluid={true}>
            <div className="account-pages">
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={10}>
                  <Card className="overflow-hidden login_fondo">
                    <div className="bg-login-footer">
                      <Row>
                        <Col className="col-7">
                          <div className="text-success p-5">
                            <h5 className="text-bienvenida text-white">
                              Restablecer Contraseña
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light"></Link>
                      </div>

                      {this.state.errorMessage && (
                        <Alert color="danger">{this.state.errorMessage}</Alert>
                      )}
                      {this.state.response_data.message && (
                        <Alert
                          color="danger"
                          response_data={this.state.response_data}
                        />
                      )}
                      <AvForm
                        className="mt-3 needs-validation form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <Row className="mb-3">
                          <Col xs={2}>
                            <Label>Nueva password</Label>
                          </Col>
                          <Col>
                            <AvField
                              name="new_password"
                              type="password"
                              required
                              className="form-control"
                              placeholder="Ingrese nueva password"
                              invalid={this.state.form_errors["new_password"]}
                              errorMessage="Ingrese nueva password"
                              onChange={(e) => this.handleChangeTextInput(e)}
                            />
                            {this.state.form_errors["new_password"] !==
                              null && (
                              <p style={{ color: "red" }}>
                                {this.state.form_errors["new_password"]}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={2}>
                            <Label>Confirmación Password</Label>
                          </Col>
                          <Col>
                            <AvField
                              name="new_password_repeat"
                              type="password"
                              validate={{
                                required: { value: true },
                                match: { value: "new_password" },
                              }}
                              invalid={
                                this.state.form_errors["new_password_repeat"]
                              }
                              placeholder="Confirme nueva password"
                              errorMessage="Confirme nueva password"
                              onChange={(e) => this.handleChangeTextInput(e)}
                            />
                            {this.state.form_errors["new_passwor_drepeat"] !==
                              null && (
                              <p style={{ color: "red" }}>
                                {this.state.form_errors["new_password_repeat"]}
                              </p>
                            )}
                          </Col>
                        </Row>
                        {(this.state.message_error ||
                          this.state.message_success) && (
                          <Row>
                            <Col>
                              <div className="mt-3 d-grid">
                                {this.state.message_error && (
                                  <Alert color="danger">
                                    {this.state.message_error}
                                  </Alert>
                                )}
                                {this.state.message_success && (
                                  <Alert color="success">
                                    {this.state.message_success}
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col className="d-grid">
                            <Button color="secondary" href="/login">
                              Iniciar sesión
                            </Button>
                          </Col>
                          <Col className="d-grid">
                            <Button
                              color="primary"
                              type="submit"
                              // onClick={() => this.cambiarContrasena()}
                            >
                              Cambiar contraseña
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>

                      <div className="p-2"></div>
                      <div className="mt-5 text-center">
                        <p>
                          © {new Date().getFullYear()}{" "}
                          <a href="https://usercode.cl/">UserCode</a>. todos los
                          derechos reservados.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
