/* eslint-disable react/prop-types */
import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { AvGroup } from "availity-reactstrap-validation";
import { Row, Col, Label, Input, FormFeedback } from "reactstrap";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Row className="align-items-center">{props.children}</Row>
  );
};

function CheckInput(props) {
  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label>{props.label}</Label>
          </Col>
        )}
        <Col>
          <Row md={props.inline ? 1 : 4} className="text-nowrap gy-2">
            {props.content.map((item, idx) => (
              <Col key={idx}>
                <AvGroup check inline={props.inline}>
                  <Label check for={`${props.name}-${idx}`}>
                    {item.label}
                  </Label>
                  <Input
                    id={`${props.name}-${idx}`}
                    name={props.name}
                    onChange={() => props.onChange(props.name, item.value)}
                    checked={
                      props.type === "checkbox"
                        ? props.values?.includes(item.value)
                        : props.value === item.value
                    }
                    placeholder={props.placeholder || "Ingrese un valor..."}
                    type={props.type}
                    disabled={props.disabled || false}
                    required={props.required}
                    invalid={Boolean(props.error)}
                  />
                  <FormFeedback invalid>
                    {props.error || "Este campo es necesario"}
                  </FormFeedback>
                </AvGroup>
              </Col>
            ))}
          </Row>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

CheckInput.propTypes = {
  //match: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
  //history: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.array,
  errorMessage: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  content: PropTypes.array,
  type: PropTypes.oneOf(["checkbox", "radio"]),
};

CheckInput.defaultProps = {
  noLabel: false,
  tyepe: "checkbox",
};

export default withRouter(CheckInput);
