import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../Table/";
import { get, del } from "helpers/ApiHelper";
import { useFetchSelect } from "components/Form";
import { SelectInput } from "components/Form";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { DateInput } from "components/Form";
import moment from "moment";

const Content = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);
  const [filters, setFilters] = useState({
    from: moment().format("YYYY-MM-DD"),
  });

  const projects = useFetchSelect("projects");
  const priorities = useFetchSelect("priorities");
  const sources = useFetchSelect("sources");
  const enviroments = useFetchSelect("enviroments");

  useEffect(() => {
    const fetchData = async () => {
      const query = new URLSearchParams(filters);

      const request = await get("logs?" + query);
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, [update, filters]);

  const handleChangeFilters = (name, value) => {
    if (!value) {
      setFilters((prev) => {
        delete prev[name];
        return { ...prev };
      });
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const formatButtons = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <Row xs={1} lg={3} className="g-1 align-items-start flex-lg-nowrap">
        <Col>
          <Link className="d-grid" to={`logs/see/${row.id}`}>
            <Button size="sm" color="info">
              Ver
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "createdAt",
      text: "Fecha",
      customType: "date",
      filter: null,
    },
    {
      dataField: "project.id",
      text: "Proyecto",
      customType: "string",
      options: projects,
      formatter: (_, row) => row.project.name,
      filter: null,
    },
    {
      dataField: "priority.id",
      text: "Prioridad",
      customType: "string",
      options: priorities,
      formatter: (_, row) => row.priority.name,
      filter: null,
    },
    {
      dataField: "source.id",
      text: "Origen",
      customType: "string",
      options: sources,
      formatter: (_, row) => row.source.name,
      filter: null,
    },
    {
      dataField: "enviroment.id",
      text: "Ambiente",
      customType: "string",
      options: enviroments,
      formatter: (_, row) => row.enviroment.name,
      filter: null,
    },
    {
      dataField: "message",
      text: "Mensaje",
      customType: "string",
      formatter: (value) => value.slice(0, 50),
      filter: null,
    },
    {
      dataField: "actions",
      text: "Acciones",
      customType: "actions-full",
      isDummyField: true,
      formatter: formatButtons,
    },
  ];

  const toggleFunction = async (id) => {
    await del("users/" + id);
    setUpdate(new Date().getTime());
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Registros / Listar</h4>
        </Col>
        {/* <Col sm={4} lx={2}> */}
        {/*   <Link to="/users/create" className="d-grid"> */}
        {/*     <Button color="info" size="lg"> */}
        {/*       Crear Usuario */}
        {/*     </Button> */}
        {/*   </Link> */}
        {/* </Col> */}
      </Row>
      <Row>
        <AvForm className="px-0">
          <fieldset>
            <Row className="align-items-center">
              <SelectInput
                noRow
                name="project_id"
                label="Proyectos"
                options={projects}
                onChange={handleChangeFilters}
              />
              <SelectInput
                noRow
                name="priority_id"
                label="Prioridad"
                options={priorities}
                onChange={handleChangeFilters}
              />
            </Row>

            <Row className="align-items-center">
              <SelectInput
                noRow
                name="enviroment_id"
                label="Ambiente"
                options={enviroments}
                onChange={handleChangeFilters}
              />
              <SelectInput
                noRow
                name="source_id"
                label="Origen"
                options={sources}
                onChange={handleChangeFilters}
              />
            </Row>

            <Row className="align-items-center">
              <DateInput
                noRow
                name="from"
                label="Desde"
                onChange={handleChangeFilters}
                value={filters.from}
              />
              <DateInput
                noRow
                name="to"
                label="Hasta"
                onChange={handleChangeFilters}
                value={filters.to}
              />
            </Row>
          </fieldset>
        </AvForm>
      </Row>
      <Row className="">
        <Table
          headers={columns}
          data={data}
          toggle={{ statusProperty: "userstatus_id", toggleFunction }}
        />
      </Row>
    </React.Fragment>
  );
};

export default Content;
