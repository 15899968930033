import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button } from "reactstrap";
import { TextAreaInput, TextInput } from "../../Form/";
import { get } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";
import moment from "moment";

const Content = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("logs/" + id);
    setData(response.data);
  };

  if (!data) return <></>;

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          <h4 className="text-white">Registro / Ver</h4>
        </Col>
      </Row>
      <Row className="p-2">
        <AvForm>
          <fieldset className="">
            <h3>Detalle del registro</h3>
            <Row className="align-items-center">
              <TextInput
                name="id"
                label="ID del registro"
                value={data.id}
                disabled={true}
                noRow
              />
              <TextInput
                name="project"
                label="Proyecto"
                value={data.project.name}
                disabled={true}
                noRow
              />
            </Row>
            <Row className="align-items-center">
              <TextInput
                name="enviroment"
                label="Ambiente"
                value={data.enviroment.name}
                disabled={true}
                noRow
              />
              <TextInput
                name="source"
                label="Origen"
                value={data.source.name}
                disabled={true}
                noRow
              />
            </Row>
            <Row className="align-items-center">
              <TextInput
                name="priority"
                label="Prioridad"
                value={data.priority.name}
                disabled={true}
                noRow
              />
              <TextInput
                name="date"
                label="Fecha"
                value={moment(data.createdAt).format("L LTS")}
                disabled={true}
                type="datetime"
                noRow
              />
            </Row>
            <TextAreaInput
              name="message"
              label="Mensaje"
              placeholder="-- Ingrese nombre --"
              value={data.message}
              disabled={true}
            />
            <TextAreaInput
              name="stacktrace"
              label="StackTrace"
              value={data.stacktrace}
              disabled={true}
            />
            <Row>
              <Col className="d-grid">
                <Link to="/" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
