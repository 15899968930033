/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Lightbox } from "../Images/";

const encodeFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

function FileInput(props) {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImg, setSelectedImg] = useState();

  const openLigthbox = (idx) => {
    setSelectedImg(idx);
    setShowLightbox(true);
  };

  return (
    <React.Fragment>
      <FormGroup className="row align-items-center">
        <Col sm={2}>
          <Label>{props.label}</Label>
        </Col>
        {!props.disabled && (
          <Col>
            <div
              className={
                props.error
                  ? "form-control border border-danger rounded is-invalid"
                  : "form-control"
              }
            >
              <Dropzone onDrop={props.handleAddFiles} disabled={props.disabled}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dropzone text-center d-flex flex-column justify-content-center"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <i className="fs-1 text-muted bx bxs-cloud-upload" />
                    <h4>
                      {props.placeholder ||
                        "Arrastre el archivo aquí o haga click para buscar"}
                    </h4>
                  </div>
                )}
              </Dropzone>
            </div>
            <FormFeedback invalid>
              {props.error || "Este campo es necesario"}
            </FormFeedback>
          </Col>
        )}
      </FormGroup>
      {props.files?.length > 0 && (
        <Row>
          <Lightbox
            isOpen={showLightbox}
            toggleOpen={() => setShowLightbox((prev) => !prev)}
            images={props.files.map((item) => item.preview)}
          />
          <Col md={{ offset: 2, size: 10 }}>
            <Row
              xs={1}
              md={3}
              className="dropzone-previews g-3"
              id="file-previews"
            >
              {props.files.map((file, idx) => {
                if (file.deleted) return <></>;

                return (
                  <Col key={"file-" + idx}>
                    <Card className="h-100">
                      <div className="h-100 position-relative">
                        <img
                          className="thumbnail"
                          alt={file.name}
                          src={file.preview}
                        />
                        <Button
                          close
                          className="position-absolute end-0 top-0 m-3 fw-bold bg-secondary rounded-border"
                          onClick={() => props.handleRemoveFile(idx)}
                          disabled={props.disabled}
                        />
                      </div>
                      <CardBody
                        className="mouse-hover"
                        onClick={() => openLigthbox(idx)}
                      >
                        <CardTitle className="text-muted font-weight-bold">
                          {`Archivo ${idx + 1}`}
                          {props.handleSelectFile && (
                            <>
                              <br />
                              <input
                                type="radio"
                                name="selected-file"
                                checked={Boolean(file.principal)}
                                disabled={props.disabled}
                                onChange={() => props.handleSelectFile(idx)}
                              />{" "}
                              ¿es principal?
                            </>
                          )}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

FileInput.propTypes = {
  //match: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
  //history: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  error: PropTypes.array,
  errorMessage: PropTypes.string,
  files: PropTypes.array,
  handleAddFiles: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FileInput;

export { encodeFile };
