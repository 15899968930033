import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../components/Sections/Users/UserForm";

const User = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {process.env.REACT_APP_PAGE_TITLE} | Mantenedor de Usuarios
        </title>
      </MetaTags>
      <Container fluid>
        <Content />
      </Container>
    </div>
  );
};

export default User;
