/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { AvGroup } from "availity-reactstrap-validation";
import { Col, FormFeedback, Label } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <AvGroup className="row align-items-center">{props.children}</AvGroup>
  );
};

function DateInput(props) {
  registerLocale("es", es);

  const onChange = (e) => {
    console.log(e);
    const value = e ? moment(e).format("YYYY-MM-DD") : e;
    props.onChange(props.name, value);
  };

  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label>{props.label}</Label>
          </Col>
        )}
        <Col>
          <DatePicker
            className="form-control"
            name={props.name}
            onChange={onChange}
            selected={props.value && moment(props.value).toDate()}
            placeholderText={props.placeholder || "Ingrese un valor..."}
            disabled={props.disabled || false}
            required={props.required}
            invalid={Boolean(props.error)}
            isClearable
            locale="es"
          />
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

DateInput.defaultProps = {
  required: true,
  disabled: false,
};

export default DateInput;
