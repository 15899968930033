import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../Table/";
import { get, del } from "helpers/ApiHelper";
import { checkRole } from "helpers/getUser";

const Content = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("projects?all=true");
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, [update]);

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "projectstatus.name",
      text: "Estado",
      customType: "select",
      options: [
        {
          value: "Activo",
          label: "Activo",
        },
        {
          value: "Inactivo",
          label: "Inactivo",
        },
      ],
    },
    {
      dataField: "actions",
      text: "Acciones",
      customType: "actions-full",
    },
  ];

  const toggleFunction = async (id) => {
    await del("projects/" + id);
    setUpdate(new Date().getTime());
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Proyectos / Listar</h4>
        </Col>
        <Col sm={4} lx={2}>
          <Link to="/projects/create" className="d-grid">
            {checkRole(["Jefe de Proyecto"]) && (
              <Button color="info" size="lg">
                Crear Proyecto
              </Button>
            )}
          </Link>
        </Col>
      </Row>
      <Row className="p-2">
        <Table
          headers={columns}
          data={data}
          toggle={{ statusProperty: "projectstatus_id", toggleFunction }}
          roles={["Jefe de Proyecto"]}
        />
      </Row>
    </React.Fragment>
  );
};

export default Content;
