import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/karubag/SVG/Asset 30.svg";
// import bgLogin from "../../assets/karubag/SVG/Asset 32.svg";
// import lightlogo from "../../assets/images/laptop-img.png";
import { post } from "helpers/ApiHelper";
import EmailInput from "components/Form/EmailInput";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: [],
      response_data: [],
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleChangeTextInput = this.handleChangeTextInput.bind(this);
  }

  handleChangeTextInput = (event) => {
    this.setState((state) => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
    }));
  };

  // handleValidSubmit
  async handleValidSubmit(event, _values) {
    event.preventDefault();
    let data = {
      email: this.state.form_data.email,
    };
    const response = await post("/users/password/recover", data);
    console.log(response.message, response.status);
    if (response.status === 200) {
      this.setState((state) => ({
        response_data: {
          ...state.response_data,
          message: response.message,
          color: "success",
        },
      }));
    } else {
      this.setState((state) => ({
        response_data: {
          ...state.response_data,
          message: response.message,
          color: "danger",
        },
      }));
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden login_fondo">
                  <div className="bg-login-footer">
                    <Row>
                      <Col className="col-12">
                        <div className="text-success p-5">
                          <h5 className="text-white">Recuperar Contraseña</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            {/* <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            /> */}
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span
                            style={{ background: "#7cac47" }}
                            className="avatar-title rounded-circle"
                          >
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="72"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="mb-3">
                          {/* <EmailInput */}
                          {/*   name="email" */}
                          {/*   label="Email" */}
                          {/*   onChange={this.handleChangeTextInput} */}
                          {/* /> */}
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Ingrese email"
                            errorMessage="Ingrese email"
                            onChange={(e) => this.handleChangeTextInput(e)}
                            type="email"
                            required
                          />
                        </div>
                        {this.state.response_data.message && (
                          <Alert color="warning">
                            {this.state.response_data.message}
                          </Alert>
                        )}

                        <div className="row">
                          <div className="col d-grid">
                            <Link
                              to="/login"
                              className="btn btn-secondary btn-block"
                            >
                              Volver
                            </Link>
                          </div>
                          <div className="col d-grid">
                            <button className="btn btn-info" type="submit">
                              Enviar
                            </button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Necesitas ayuda?
                    <Link to="/soporte">&nbsp;Soporte</Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()}{" "}
                    <a
                      href="https://www.usercode.cl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UserCode SpA
                    </a>{" "}
                    todos los derechos reservados.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.func,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);
