const checkRole = (testRoles) => {
  // no role pass, all roles allowed
  if (!testRoles || (testRoles && !testRoles.length)) return true;

  const user = JSON.parse(window.localStorage.getItem("authUser"));

  if (!user) return false;

  const roles = user.roles.map((item) => item.name);

  // Return true if at least one role match, false if any role match
  return testRoles.some((item) => roles.includes(item));
};

export { checkRole };
