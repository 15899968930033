import { API_URL } from "helpers/ApiHelper";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import usercoderImg from "assets/Logger/SVG/Logo 27-05_Mesa de trabajo 1.svg";
import { Lightbox } from "../../Images/";

moment.updateLocale("es");

const ReplyMessage = ({ message, clientImg }) => {
  const date = useRef(moment(message.createdAt));
  const is_usercoder = useRef(message.user.usercoder !== null);

  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImg, setSelectedImg] = useState();

  const openLigthbox = (idx) => {
    setSelectedImg(idx);
    setShowLightbox(true);
  };

  return (
    <div className="mb-3 p-3 border border-secondary">
      <Row className="">
        <Col xs={2} className="text-center">
          <div className="mb-2">Agente</div>
          {is_usercoder.current ? (
            <img src={usercoderImg} alt="Agente" />
          ) : (
            <img className="w-100" src={API_URL + clientImg} alt="Cliente" />
          )}
        </Col>
        <Col>
          <div>Fecha: {date.current.format("LLL")}</div>
          <p>{message.description}</p>
        </Col>
      </Row>
      <Row xs={3} xl={4} className="gy-3 mt-3">
        {message.replyimages.map((item, idx) => (
          <Col
            key={idx}
            className="mouse-hover"
            onClick={() => openLigthbox(idx)}
          >
            <img
              className="thumbnail"
              src={API_URL + item.path}
              alt={`imagen ${idx + 1}`}
            />
          </Col>
        ))}
      </Row>
      <Lightbox
        isOpen={showLightbox}
        toggleOpen={() => setShowLightbox((prev) => !prev)}
        images={message.replyimages.map((item) => API_URL + item.path)}
        selectedImg={selectedImg}
      />
    </div>
  );
};

export default ReplyMessage;
