import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { useForm, TextAreaInput, FileInput, encodeFile } from "components/Form";

const ReplyForm = ({ ticket }) => {
  const { requestMessage, errors, sendForm } = useForm();
  const [data, setData] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));

    if (!user) return;

    handleInput("user_id", user.id);
    handleInput("ticket_id", ticket.id);
  }, [ticket]);

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddImages = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const images = await Promise.all(
      files.map(async (img, idx) => {
        const data = await encodeFile(img);
        const type = img.type.split("/")[1];
        return {
          preview: img.preview,
          name: `archivo ${idx + 1}`,
          pos: idx + 1,
          file: {
            ...img,
            type,
            data,
            new: true,
          },
        };
      })
    );

    setData((prevData) => ({
      ...prevData,
      replyimages: prevData.replyimages
        ? prevData.replyimages.concat(images)
        : images,
    }));
  };

  const handleRemoveImages = (removed_idx) => {
    setData((prevData) => {
      const img = prevData.replyimages[removed_idx];

      if (img.new) {
        // Local file, Remove from the array
        prevData.replyimages = prevData.ticketimages.filter(
          (_, idx) => idx !== removed_idx
        );
      } else {
        // Remote file, Set flag to remove from the back
        img.deleted = true;
      }

      return {
        ...prevData,
      };
    });
  };

  return (
    <Row className="p-2">
      <AvForm onValidSubmit={() => sendForm("replies", data)}>
        <fieldset className="">
          <h3>Responde</h3>
          <TextAreaInput
            name="description"
            label="Mensaje"
            placeholder="-- Ingrese respuesta --"
            value={data.descripción}
            onChange={handleInput}
            error={errors.descripción}
          />
          <FileInput
            label="Imágenes"
            handleAddFiles={handleAddImages}
            handleRemoveFile={handleRemoveImages}
            files={data.replyimages}
            error={errors.replyimages}
          />
          {requestMessage && (
            <Row>
              <Alert color={Object.keys(errors).length ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Row>
          )}
          <Row>
            <Col className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Col>
            <Col className="d-grid">
              <Button type="submit" color="info" size="lg">
                Enviar
              </Button>
            </Col>
          </Row>
        </fieldset>
      </AvForm>
    </Row>
  );
};

export default ReplyForm;
