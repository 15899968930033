import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Alert,
} from "reactstrap";
import { Table } from "../../Table/";
import { get, put } from "helpers/ApiHelper";
import { useFetchSelect } from "components/Form";
import { checkRole } from "helpers/getUser";
import { isNumber } from "lodash";
import UsercoderModal from "./UsercoderModal";

const Content = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);

  const projects = useFetchSelect("projects");
  const priorities = useFetchSelect("priorities");
  const [statuses, setStatuses] = useState([]);

  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalUsercoder, setShowModalUsercoder] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("ticketstatuses");
      setStatuses(
        request.status === 200
          ? request.data.map((item) => ({ label: item.status, value: item.id }))
          : []
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("tickets");
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, [update]);

  const toggleModalArchive = (id) => {
    setShowModalArchive((prev) => !prev);
    setModalId(isNumber(id) ? id : null);
    setTimeout(() => setErrorMessage(""), 500);
  };

  const toggleModalUsercoder = (id) => {
    setShowModalUsercoder((prev) => !prev);
    setModalId(isNumber(id) ? id : null);
  };

  const archiveFunction = async () => {
    const response = await put(`tickets/${modalId}/status/2`);

    if (response.status !== 200) {
      setErrorMessage(response.data.message);
    } else {
      setUpdate(new Date().getTime());
      toggleModalArchive();
    }
  };

  const formatButtons = (_cell, row) => {
    return (
      <Row xs={1} lg={3} className="g-1 align-items-start flex-lg-nowrap">
        <Col>
          <Link
            className="d-grid"
            to={`${window.location.pathname}/see/${row.id}`}
          >
            <Button size="sm" color="info">
              Ver
            </Button>
          </Link>
        </Col>
        {row.ticketstatus_id !== 2 && checkRole(["Jefe de Proyecto"]) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="success"
              onClick={() => toggleModalUsercoder(row.id)}
            >
              Asignar
            </Button>
          </Col>
        )}
        {row.ticketstatus_id !== 2 && checkRole(["Jefe de Proyecto"]) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="danger"
              onClick={() => toggleModalArchive(row.id)}
            >
              Archivar
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "title",
      text: "Titulo",
      customType: "string",
    },
    {
      dataField: "priority.id",
      text: "Prioridad",
      customType: "select",
      options: priorities,
      formatter: (_, row) => row.priority.name,
    },
    {
      dataField: "project.id",
      text: "Proyecto",
      customType: "select",
      options: projects,
      formatter: (_, row) => row.project.name,
    },
    {
      dataField: "ticketstatus.id",
      text: "Estado",
      customType: "select",
      options: statuses,
      formatter: (_, row) => row.ticketstatus.status,
    },
    {
      dataField: "actions",
      text: "Acciones",
      formatter: formatButtons,
    },
  ];

  return (
    <React.Fragment>
      <UsercoderModal
        showModal={showModalUsercoder}
        toggleModal={toggleModalUsercoder}
        ticket={data.find((item) => item.id === modalId)}
      />
      <Modal centered toggle={toggleModalArchive} isOpen={showModalArchive}>
        <ModalHeader className="justify-content-center">
          ¿Esta seguro?
        </ModalHeader>
        <ModalBody className="">
          {errorMessage && (
            <div>
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          )}
          <section className="hstack justify-content-around">
            <div className="d-grid">
              <Button size="lg" onClick={toggleModalArchive}>
                Cancelar
              </Button>
            </div>
            <div className="d-grid">
              <Button color="info" size="lg" onClick={archiveFunction}>
                Aceptar
              </Button>
            </div>
          </section>
        </ModalBody>
      </Modal>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Tickets / Listar</h4>
        </Col>
        <Col sm={4} lx={2}>
          <Link to="/tickets/create" className="d-grid">
            {checkRole(["Cliente"]) && (
              <Button color="info" size="lg">
                Crear Ticket
              </Button>
            )}
          </Link>
        </Col>
      </Row>
      <Row className="p-2">
        <Table
          headers={columns}
          data={data}
          toggle={{ statusProperty: "userstatus_id", archiveFunction }}
          deleteText="Archivar"
        />
      </Row>
    </React.Fragment>
  );
};

export default Content;
