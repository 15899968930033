import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { AvInput } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { FormFeedback, Label } from "reactstrap";
import { TextInput, DualListInput } from "../../Form/";
import { API_URL, get } from "helpers/ApiHelper";
import { useForm } from "components/Form";
import { useParams, Link } from "react-router-dom";
import { encodeFile } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const { requestMessage, errors, sendForm } = useForm();
  const [data, setData] = useState({});
  const [aux, setAux] = useState({});

  const [usercoders, setUsercoders] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const mapUser = (item) => ({
      label: `${item.user.name} ${item.user.lastname}`,
      value: item.user.id,
    });

    get("usercoders").then((res) => {
      console.log(res.data);
      setUsercoders(res.data.map(mapUser));
    });

    get("clients").then((res) => {
      console.log(res.data);
      setClients(res.data.map(mapUser));
    });
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("projects/" + id);
    const { data } = response;
    data.users = data.users.map((item) => item.id);
    setData(response.data);
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAcceptFile = async (files) => {
    console.log(files[0]);
    const data = await encodeFile(files[0]);
    const type = files[0].type.split("/")[1];
    setData((prevData) => ({ ...prevData, image: { type, data, new: true } }));
    setAux((prevData) => ({
      ...prevData,
      image: URL.createObjectURL(files[0]),
    }));
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Proyectos / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("projects", data, id)}>
          <fieldset>
            <h3>Detalle Proyecto</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            <DualListInput
              name="users"
              label="Usercoders"
              options={usercoders}
              value={data.users}
              onChange={handleInput}
              error={errors.users}
              disabled={see}
            />
            <DualListInput
              name="users"
              label="Clientes"
              options={clients}
              value={data.users}
              onChange={handleInput}
              error={errors.users}
              disabled={see}
            />
            <Row>
              <Col sm={2}>
                <Label>Imagen</Label>
              </Col>
              <Col>
                <AvInput
                  name="image"
                  onChange={(e) => handleAcceptFile(e.target.files)}
                  type="file"
                  disabled={see}
                  required={!data.image}
                  invalid={errors.image}
                />
                <FormFeedback invalid>
                  {errors.image || "Este campo es necesario"}
                </FormFeedback>
              </Col>
            </Row>
            <Row>
              <Col sm={{ offset: 2, size: 10 }}>
                {typeof data.image === "string" ? (
                  <img
                    src={API_URL + data.image}
                    alt={`Logo de ${data.name}`}
                    className="img-responsive"
                  />
                ) : (
                  <img
                    className="img-responsive"
                    src={aux.image}
                    alt={`Logo de ${data.name}`}
                  />
                )}
              </Col>
            </Row>
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/projects" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
