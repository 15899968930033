import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextInput, SelectInput, encodeFile } from "../../Form/";
import { API_URL, get, put } from "helpers/ApiHelper";
import { useForm } from "components/Form";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect } from "components/Form";
import { FileInput } from "components/Form";
import { TextAreaInput } from "components/Form";
import ArchiveModal from "./ArchiveModal";
import ReplyMessage from "./ReplyMessage";
import ReplyForm from "./ReplyForm";
import { checkRole } from "helpers/getUser";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const { requestMessage, errors, sendForm } = useForm();
  const [data, setData] = useState({ is_usercoder: false });
  const [replies, setReplies] = useState([]);

  const projects = useFetchSelect("projects");
  const priorities = useFetchSelect("priorities");
  const [labels, setLabels] = useState([]);

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const toggleArchiveModal = () => setShowArchiveModal((prev) => !prev);

  useEffect(() => {
    get("labels").then((res) => {
      if (res.status !== 200) return;

      setLabels(
        res.data.map((item) => ({ label: item.label, value: item.id }))
      );
    });
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));

    get("users/" + user.id).then((res) => {
      if (res.status !== 200) return;

      const { client } = res.data;

      if (!client) return;

      handleInput("client_id", client.id);
    });
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("tickets/" + id);

    if (response.data.usercoder_id === null) {
      delete response.data.usercoder_id;
    }

    setData({
      ...response.data,
      ticketimages: response.data.ticketimages.map((item) => ({
        ...item,
        preview: API_URL + item.path,
      })),
    });
  };

  useEffect(() => {
    const fetchReplies = async () => {
      const response = await get("replies?ticket_id=" + id);

      if (response.status !== 200) return;

      setReplies(response.data);
    };

    fetchReplies();
  }, [id]);

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputLabel = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
    put(`tickets/${id}/label/${value}`);
  };

  const handleAddImages = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const images = await Promise.all(
      files.map(async (img, idx) => {
        const data = await encodeFile(img);
        const type = img.type.split("/")[1];
        return {
          preview: img.preview,
          name: `archivo ${idx + 1}`,
          pos: idx + 1,
          file: {
            ...img,
            type,
            data,
            new: true,
          },
        };
      })
    );

    setData((prevData) => ({
      ...prevData,
      ticketimages: prevData.images ? prevData.images.concat(images) : images,
    }));
  };

  const handleRemoveImages = (removed_idx) => {
    setData((prevData) => {
      const img = prevData.ticketimages[removed_idx];

      if (img.new) {
        // Local file, Remove from the array
        prevData.ticketimages = prevData.ticketimages.filter(
          (_, idx) => idx !== removed_idx
        );
      } else {
        // Remote file, Set flag to remove from the back
        img.deleted = true;
      }

      return {
        ...prevData,
      };
    });
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Ticket / {text}</h4>;
  };

  return (
    <React.Fragment>
      <ArchiveModal
        showModal={showArchiveModal}
        toggleModal={toggleArchiveModal}
        ticketId={data.id}
      />
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("tickets", data, id)}>
          <fieldset>
            <Row className="align-items-center">
              <Col>
                <h3>Detalle Ticket</h3>
              </Col>
              {see && checkRole(["Jefe de Proyecto", "Desarrollador", "QA"]) && (
                <Col>
                  <AvForm>
                    <SelectInput
                      name="label_id"
                      label="Label"
                      placeholder="-- Ingrese label --"
                      options={labels}
                      value={data.label_id}
                      onChange={handleInputLabel}
                      error={errors.label_id}
                    />
                  </AvForm>
                </Col>
              )}
            </Row>
            {data.id && (
              <TextInput
                name="id"
                label="ID"
                placeholder="-- Ingrese ID --"
                value={data.id}
                onChange={handleInput}
                error={errors.id}
                disabled={true}
              />
            )}
            <TextInput
              name="title"
              label="Título"
              placeholder="-- Ingrese título --"
              value={data.title}
              onChange={handleInput}
              error={errors.title}
              disabled={see}
            />
            <SelectInput
              name="project_id"
              label="Proyecto"
              placeholder="-- Ingrese proyecto --"
              options={projects}
              value={data.project_id}
              onChange={handleInput}
              error={errors.project_id}
              disabled={see}
            />
            <SelectInput
              name="priority_id"
              label="Prioridad"
              placeholder="-- Ingrese prioridad --"
              options={priorities}
              value={data.priority_id}
              onChange={handleInput}
              error={errors.priority_id}
              disabled={see}
            />

            {data.ticketstatus_id && (
              <TextInput
                name="state"
                label="Estado"
                placeholder="-- Ingrese Estado --"
                value={data.ticketstatus.status}
                onChange={handleInput}
                error={errors.email}
                disabled={true}
              />
            )}
            <TextAreaInput
              name="description"
              label="Descripción"
              placeholder="-- Ingrese descripción --"
              value={data.descripción}
              onChange={handleInput}
              error={errors.descripción}
              disabled={see}
            />
            <FileInput
              label="Imágenes"
              handleAddFiles={handleAddImages}
              handleRemoveFile={handleRemoveImages}
              files={data.ticketimages}
              error={errors.ticketimages}
              disabled={see}
            />
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/tickets" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
              {checkRole(["Jefe de Proyecto"]) && see && (
                <Col className="d-grid">
                  <Button
                    type="submit"
                    size="lg"
                    color="info"
                    onClick={toggleArchiveModal}
                  >
                    Archivar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
      {see && (
        <>
          <Row className="p-2">
            <form>
              <fieldset className="">
                {replies.map((item, idx) => (
                  <ReplyMessage
                    key={idx}
                    message={item}
                    clientImg={data.project?.image}
                  />
                ))}
              </fieldset>
            </form>
          </Row>
          <ReplyForm ticket={data} />{" "}
        </>
      )}
    </React.Fragment>
  );
};

export default Content;
